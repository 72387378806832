@import "./../../../sass/partials/images";
@import "./../../../sass/partials/colors";
@import "./../../../sass/partials/base";

.language-dropdown {
  position: relative;
  display: inline-block;
}

.menu-button {
  background-color: #fff;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.dropdown-content {
  display: block;
  position: absolute;
  background-color: #5B5B5E;
  min-width: 215px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 99999;
  border-radius: 5px;
  margin-top: 7px;
  padding: 10px;
  font-size: 15px;

  .menu-header-label {
    font-family: SFProText-Semibold;
    font-size: 18px;
    color: #F5F5F7;
    letter-spacing: -0.4px;
    line-height: 21.6px;
    font-weight: 600;
    padding-top: 10px;
    padding-left: 10px;
  }

  .getting-started {
    padding: 20px 10px;
    border-top: 1px solid #ddd;

    .userGuide-icon {
      @include create-image($userGuideIcon, 30px, 14px, 13px, 24px 24px, 0px -3px, "");
    }
  }
}


.dropdown-content ul {
  list-style-type: none;
  padding: 0;
}

.dropdown-content ul li {
  padding: 5px;
  cursor: pointer;
}

.checkmark {
  color: #ccc;
  margin-right: 10px;
}

.checkmark--visible {
  opacity: 1;
}

.checkmark--transparent {
  opacity: 0;
}


.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  /* Semi-transparent background */
  z-index: 15;
  /* Behind dropdown content but above everything else */
}