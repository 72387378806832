@import "../../../sass/partials/images";

$selection-color-blue: #0A58DC;
$selection-color: #BEDDFD;

.dropdown {
  position: relative;

  .dropdown-selected {
    padding: 0.5rem 1rem;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #c1c1c1;
    border-radius: 4px;
    background: #EDF6FF;

    .dp-selection {
      width: 90%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-family: Medium;
    }

    .dp-downArrow {}
  }

  .dropdown-popup {
    position: absolute;
    right: 0;
    z-index: 1001;
    box-shadow: 0 5px 8px 2px rgba(0, 0, 0, 0.14);
    background: #FFFFFF;
    opacity: 0.98;
    border: 1px solid #c1c1c1;
    border-radius: 10px;
    min-width: 220px;
    margin-top: 10px;

    .store-content {
      padding: 10px;
      width: 90%;

      //--bricks-text-field-width:100% !important;
      .search-result {
        max-height: 250px;
        min-height: 250px;
        overflow-x: hidden;
        overflow-y: auto;
        padding-top: 10px;
        .custom-radio {
          padding: 6px;
        }
        @media screen and (max-width: 600px) {
          .custom-radio {
            padding: 6px;
            --bricks-radio-button-size: 10px;
          }
      }
      }
    }

    .dropdown-footer {
      height: 50px;
      display: flex;
      justify-content: space-evenly;
      text-align: center;
      // padding-left: 45%;


      .btn {
        height: 16px;
        border: 2px solid rgb(22, 113, 234);
        border-radius: 5px;
        padding: 4px;
        margin: 0px 5px;
        width: 80px;
        color: rgb(17, 92, 191);
        font-weight: 600;
        font-size: 14px;
        cursor: pointer;
      }

      .btn:hover {
        color: white;
        background: rgb(22, 113, 234);
      }

      .disable {
        pointer-events: none;
        opacity: 0.3;
      }

    }

  }
  @media (max-width: 768px) {
    .dropdown-popup {
        left: 0;
        right: auto; 
    }
}

  .custom-select {
    label {
      font-size: 12px;
      color: #6e6e73;
      font-weight: 600;
    }
  }

  .WhtColor {
    color: #fff;
  }

  .popup-overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    opacity: 0.1;
    background: #F9F9F9;
    z-index: 999;
  }

  .multi-select-selected-expand-arrow:after {
    //position: absolute;
    content: "";
    //top: 50%;
    //right: 0%;
    background: $expandIcon no-repeat;
    padding-left: 19px;
    padding-bottom: 5px;
    background-size: 18px 18px;
    background-position: -5px 0px;
    border: 9px solid transparent;
  }

  .multi-select-selected-collapse-arrow:after {
    //position: absolute;
    content: "";
    //top: 50%;
    //right: 0%;
    background: $collapseIcon no-repeat;
    padding-left: 19px;
    padding-bottom: 5px;
    background-size: 18px 18px;
    background-position: -5px 0px;
    border: 9px solid transparent;
  }
}