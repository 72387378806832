@import "../../sass/partials/base";
@import "../../sass/partials/images";
@import "../../sass/partials/colors";

.mobile-calender-container {
  .calender-header {
    font-size: 30px;
    font-weight: 600;
    padding-bottom: 15px;
  }

  .popup-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    max-height: 90%;
    background-color: white;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    transition: all 0.5s ease-in-out;

    /* Start popup off-screen */
    transform: translateY(100%);
    overflow: hidden;

    /* Initial state: hidden and off the bottom */
    transform: translateY(100%);
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;

    .popup-header {
      /* Light red background */
      border-radius: 16px 16px 0 0;
      /* Rounded top corners */
      padding: 20px 35px 3px 35px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
    }

    .popup-title {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #ccc;
      line-height: 3;
      align-items: center;
      width: 100%;
    }

    .event-name-Unexcused {
      color: #d9534f;
      /* Red color */
      font-weight: bold;
      font-size: 16px;
    }
    .event-name-Excused {
      color: #8e44ad;
      /* Red color */
      font-weight: bold;
      font-size: 16px;
    }
    .event-name-OutofScope {
      color: #949499;
      font-weight: bold;
      font-size: 16px;
    }

    .status-badge-Unexcused {
      background-color: #ec5a50;
      color: $white;
      padding: 5px 10px;
      border-radius: 20px;
      font-size: 16px;
      font-weight: bold;
    }
    .status-badge-Excused {
      background-color: #9d6faf;
      color: $white;
      padding: 5px 10px;
      border-radius: 20px;
      font-size: 16px;
      font-weight: bold;
    }
    .status-badge-NoEvent {
      background-color: #5dc09c;
      color: $white;
      padding: 5px 10px;
      border-radius: 20px;
      font-size: 16px;
      font-weight: bold;
    }
    .status-badge-OutofScope {
      background-color: #B1B1B5;
      color: $white;
      padding: 5px 10px;
      border-radius: 20px;
      font-size: 16px;
      font-weight: bold;
    }

    .NoEvent {
      background-color: #f0faf4;
    }
    .Unexcused {
      background-color: #ffecea;
    }
    .Excused {
      background-color: #9d6faf30;
    }
    .OutofScope{
      background-color: #efefef;
    }

    .close-btn {
      background: none;
      border: none;
      cursor: pointer;
      position: absolute;
      top: 10px;
      right: 10px;
    }

    .close-btn .close-icon {
      font-size: 16px;
      color: #888;
    }

    .popup-content {
      //padding: 0px 50px;
      :nth-child(3) {
        // border-bottom: 1px solid #ccc;
        margin-left: 30px;
        margin-right: 30px;
        padding-left: 40px;
      }
    }

    .popup-row {
      display: flex;
      align-items: center;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 70px;
    }
    .separator-with-border{
      border-bottom: 1px solid #ccc;
    }
    .separator-without-border{
      border-bottom: 1px solid #fff;
    }

    .icon {
      font-size: 18px;
      margin-right: 23px;
    }

    .text {
      font-size: 16px;
      color: #555;
      padding-left:5px;
    }

    .impact .text {
      color: #d9534f;
      font-weight: bold;
    }
  }

  .popup-container.show {
    transform: translateY(0); /* Popup visible */
    /* Maximum height for the sliding effect */
    /* For dynamic height, set to `auto` and adjust via JS */
  }

  .popup-content {
    .event-tooltip-calendar-icon {
      @include create-image($date, 23px, 23px, 0px, 18px 18px, 0px 3px, "");
  }
  .event-tooltip-clock-icon {
    @include create-image($clock, 23px, 23px, 0px, 18px 18px, 0px 3px, "");
}
.event-tooltip-shift-icon {
  @include create-image($shiftTime, 23px, 23px, 0px, 20px 20px, 0px 3px, "");
}
.event-tooltip-impact-icon {
    @include create-image($impactTime, 23px, 0px, 0px, 18px 17px, 1px 0px, "");
}
  }

  .calender-section-container {
    margin-top:25px;
    width: 100%;
    // max-width: 400px;
    // margin: 0 auto;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    .popup-container {
      position: fixed;
      /* Fix position to the viewport */
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      background-color: $white;
      border: 1px solid $gainsboro;
      border-radius: 4px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      overflow: hidden;
      transition:
        max-height 0.3s ease-out,
        opacity 0.3s ease-out;
      opacity: 0;
      visibility: hidden;
      max-height: 0;
      /* Initial height */
      width: 90%;
      /* Adjust width as needed */
      z-index: 1000;
      /* Ensure it's on top of other content */
    }

    .popup-container.show {
      opacity: 1;
      visibility: visible;
      max-height: 500px;
      /* Maximum height for the sliding effect */
      /* For dynamic height, set to `auto` and adjust via JS */
    }

    .popup-content {
      padding: 10px;
    }

    @media (max-width: 600px) {
      max-width: 100%;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin-bottom: 15px;
    // border-bottom: 1px solid $gainsboro;
    // padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;

    h1 {
      font-size: 1.5rem;

      @media (max-width: 600px) {
        font-size: 1.3rem;
      }
    }

    .date-range {
      font-size: 0.9rem;
      color: #555;

      @media (max-width: 600px) {
        font-size: 0.8rem;
      }
    }
  }
  .header-week{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 15px;
    font-size: 1.15rem;
    font-weight: 600;
    border-bottom:1px solid #b5b5b5;

    .date-range {
      font-size: 0.9rem;
      color: #555;

      @media (max-width: 600px) {
        font-size: 0.8rem;
      }
    }
  }

  .week {
    display: flex;
    flex-direction: column;
  }

  .day {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    // border-bottom: 1px solid $gainsboro;

    &:last-child {
      border-bottom: none;
    }

    .day-name {
      font-weight: bold;
      font-size: 1.1rem;

      @media (max-width: 600px) {
        font-size: 1rem;
      }
    }
  }
  .attendance-record {
    
    padding: 22px;
    border-radius: 8px;

    .day-container{
      border-bottom: 1px solid $gainsboro;
      padding-bottom:10px;
      .day-content{
        padding-left: 10px;
        padding-right: 10px;
        .event {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 8px;
          .status-text {
            font-size: 0.85rem;
            color: #000;
    
            @media (max-width: 600px) {
              font-size: 0.75rem;
              padding: 3px 0px;
              font-weight: 600;
            }
    
            &.Excused {
              color: #8e44ad;
            }
    
            &.Unexcused {
              color: #ec5a50;
            }
    
            &.NoEvent {
              color: #5dc09c;
            }
            &.OutofScope{
              color:#B1B1B5
            }
          }
          .badge {
            padding: 3px 10px;
            border-radius: 12px;
            font-size: 0.85rem;
            color: white;
    
            @media (max-width: 600px) {
              font-size: 0.75rem;
              padding: 2px 8px;
              font-weight: 600;
            }
    
            &.Excused {
              background-color: #9d6faf;
            }
    
            &.Unexcused {
              background-color: #ec5a50;
            }
    
            &.NoEvent {
              background-color: #5dc09c;
            }
            &.OutofScope {
              background-color: #B1B1B5;
            }
          }
        }
      }

    }
    .day-container:last-child {
      border: none;
      padding-bottom: 10px;
  }
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 10px;
      padding-right: 10px;
      padding-top:15px;
      .day{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 0px;
      }
    }
    
    .day {
      font-weight: bold;
      font-size: 18px;
      color: #000; /* Black */
    }
    
    .date {
      font-size: 14px;
      color: #7a7a7a; /* Gray color */
    }
    
   
    
    .event-label {
      color: #b22222; /* Dark red color for event label */
      font-size: 16px;
    }
    
    .status-badge {
      background-color: #b22222; /* Dark red background for the badge */
      color: white;
      padding: 3px 8px;
      border-radius: 15px;
      font-size: 12px;
      text-align: center;
    }
    
  }
  
  
}
