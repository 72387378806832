@import "./../../sass/partials/images";
@import "./../../sass/partials/colors";
@import "./../../sass/partials/base";

.AttendanceEvent {
  .category-checkbox {
    // padding:0.3rem 0.1rem;
  }
  .sub-category-checkbox {
    margin-left: 25px;
  }
  .category-label {
    padding-left: 10px;
  }
  .group-flex {
    display: flex;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .custom-checkbox {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 15px;
    height: 15px;
    background-color: $white;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    transition: background-color 200 ease-in-out;
  }

  .custom-checkbox.checked {
    background-color: rgb(22, 113, 234);
    border-color: rgb(22, 113, 234);
  }

  .custom-checkbox.checked::after {
    content: "\2713\0020";
    font-size: 0.9rem;
    color: $white;
  }
}
