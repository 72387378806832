@import "../../sass/partials/colors";

.tableLoader {
   background: none repeat scroll 0 0 $gainsboro;
   height: 100%;
   opacity: 0.7;
   top: 0px;
   width: 100%;
   position: relative;
   overflow: hidden;
   z-index: 99999;
}
.parentLoad {
   background: none repeat scroll 0 0 $gainsboro;
   height: 100%;
   left: 0px;
   opacity: 1;
   position: fixed;
   top: 0px;
   width: 100%;
   z-index: 999999999;
}

.loading {
   top: 30%;
   left: 50%;
   width: 200px;
   margin-left: -100px;
   position: absolute;
   text-align: center;
   padding-top: 7px;
}
.loading div {
   width: 10px !important;
   height: 10px !important;
   background-color: rgba(180, 180, 180, 0.7) !important;
   margin-left: 10px !important;
   border-radius: 100% !important;
   display: inline-block !important;
   box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
   -webkit-animation: loading 1.4s infinite ease-in-out !important;
   animation: loading 1.4s infinite ease-in-out !important;
   -webkit-animation-fill-mode: both !important;
   animation-fill-mode: both !important;
   z-index: 2000 !important;
}

.loading .dot1 {
   -webkit-animation-delay: -0.32s !important;
   animation-delay: -0.32s !important;
}
.loading .dot2 {
   -webkit-animation-delay: -0.16s !important;
   animation-delay: -0.16s !important;
}

@keyframes loading {
   0%,
   80%,
   100% {
      -webkit-transform: scale(0);
      background: #5db2f3;
   }

   40% {
      -webkit-transform: scale(1);
      background: #5db2f3;
   }
}

.trend-table-parentLoad {
   background: none repeat scroll 0 0 $gainsboro;
   height: 500px;
   left: 0px;
   opacity: 1;
   top: 0px;
   width: 100%;
}

.trend-table-loading {
   left: 50%;
   width: 200px;
   margin-left: -100px;
   margin-top: 10%;
   position: absolute;
   text-align: center;
   padding-top: 7px;
}
.trend-table-loading div {
   width: 10px !important;
   height: 10px !important;
   background-color: rgba(180, 180, 180, 0.7) !important;
   margin-left: 10px !important;
   border-radius: 100% !important;
   display: inline-block !important;
   box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
   -webkit-animation: loading 1.4s infinite ease-in-out !important;
   animation: loading 1.4s infinite ease-in-out !important;
   -webkit-animation-fill-mode: both !important;
   animation-fill-mode: both !important;
   z-index: 2000 !important;
}

.trend-table-loading .trend-table-dot1 {
   -webkit-animation-delay: -0.32s !important;
   animation-delay: -0.32s !important;
}
.trend-table-loading .trend-table-dot2 {
   -webkit-animation-delay: -0.16s !important;
   animation-delay: -0.16s !important;
}

@keyframes trend-table-loading {
   0%,
   80%,
   100% {
      -webkit-transform: scale(0);
      background: #5db2f3;
   }

   40% {
      -webkit-transform: scale(1);
      background: #5db2f3;
   }
}
