@import "../../../sass/partials/colors";
@import "../../../sass/partials/images";
@import "../../../sass/partials/base";

.inapp-notification-popup{
    .inapp-notification-popup-modal{
        .inapp-notification-header {
            text-align: center;
            font-weight: bold;
            margin-bottom: 10px;
        }    
    }
}
bricks-dialog::slot(header) {
    text-align: center;
    font-weight: bold;
    margin-bottom: 10px;
  }
:host(.inapp-notification-header ) {
    text-align: center;
    font-weight: bold;
    margin-bottom: 10px;
}  