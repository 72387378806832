@mixin create-image($image, $padding-left, $padding-bottom, $padding-right, $background-size, $background-position, $color) {
    background: $image no-repeat;
    padding: {
        left: $padding-left;
        bottom: $padding-bottom;
        right: $padding-right;
    }
    background: {
        size: $background-size;
        position: $background-position;
    }
    color: $color;
    cursor: pointer;
}