@import "./../../sass/partials/images";
@import "./../../sass/partials/colors";
@import "./../../sass/partials/base";

#mobile-header {
    font-size: 1.1rem;
    position: fixed;
    width: 100%;
    top: 0px;
    border-bottom: solid 1px #d2d2d7;
    clear: both;
    background: rgba(0, 0, 0);
    z-index: 999999;
    display: flex;
    flex-direction: column;

    &.corporate {
        height: 208px;
    }

    &.non-corporate {
        height: 120px;
    }

    .header-section {
        // width: 95%;
        height: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0px 10px; // Add padding for better spacing
        color: $white;

        // Adjust header section background
        &.header-section {
            background: #000;
            border-bottom: 1px solid #2f2f2f;
        }

        .header-title {
            font-size: 1.1rem;
            font-weight: 600;
        }

        .left-section {
            // --bricks-menu-item-bg-color:#000;
            // --bricks-menu-item-color:#fff
        }
    }

    .filter-section {
        // width: 95%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0px 10px; // Add padding for better spacing
        color: $white;

        .filter-title {
            padding-top: 10px;
            font-size: 14px;
            color: #a1a1a6;
            font-weight:550;
        }

        .timeperiod-value,
        .excusal-value,
        .absencetype-value {
            font-size: 16px;
            color: $white;
            letter-spacing: 0;
            line-height: 15.96px;
            font-weight: 500;
        }

        .date-range {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 0.7rem;
            color: #a1a1a6;

            .hypen {
                padding-left: 2px;
                padding-right: 2px;
            }
        }
    }

    .corporate-filter {
        display: grid;
        grid-template-columns: 2.1fr 1fr;
        grid-template-rows: auto auto;
        gap: 10px;
        width: 92%;
        padding: 15px;
        background: rgba(0, 0, 0);

        .filter-title {
            padding-top: 10px;
            font-size: 12px;
            color: #a1a1a6;
            font-weight:550;
        }

        .filter-value {
            font-size: 16px;
            color: $white;
            letter-spacing: 0;
            line-height: 15.96px;
            font-weight: 500;
        }

        .date-range {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 0.7rem;
            color: #a1a1a6;

            .hypen {
                padding-left: 2px;
                padding-right: 2px;
            }
        }
    }

    .expand-collapse-section {
        display: flex;
        justify-content: center;

        .expand-collapse-expand {
            content: "";
            right: 2%;
            background: $headerExpand no-repeat;
            padding-left: 28px;
            padding-bottom: 2px;
            background-size: 20px 15px;
            background-position: 0px -5px;
            border: 9px solid transparent;
        }

        .expand-collapse-collapse {
            content: "";
            right: 2%;
            background: $headerCollapse no-repeat;
            padding-left: 28px;
            padding-bottom: 2px;
            background-size: 20px 15px;
            background-position: 0px -5px;
            border: 9px solid transparent;
        }
    }

    .filter-info-popup {
        position: fixed;
        z-index: 9999;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: none;
        background-color: rgba(0, 0, 0, 0.4);
        top: 120px;
        margin-top: 1px;
        opacity: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        #FilterContainer {
            font-size: 14px;
            position: fixed;
            width: 100%;
            top: 120px;
            background: $white;
            border: 1px solid #f5f5f7;
            display: flex;
            flex-direction: column;
            height: 80%;

            #inner-FilterContainer {
                padding-bottom: 60px;
                overflow-y: auto;

                .category-checkbox {
                    .category-label {
                        font-size: var(--bricks-radio-button-label-font-size, 1rem);
                        font-weight: 450;
                        padding-left: 10px;
                    }
                }

                .section {
                    background-color: $white;
                    // padding: 15px;
                    // --bricks-radio-button-size: 10px;

                    @media screen and (max-width: 600px) {
                        // --bricks-radio-button-size: 10px;
                    }

                    hr {
                        margin-block-start: 1rem;
                        border-top: 1px solid grey;
                    }

                    bricks-radio-button-group::part(radio-group-label) {
                        font-size: 4vw;
                        color: $blackRussian;
                        font-weight: 500;
                    }

                    bricks-radio-button::part(label) {
                        color: $blackRussian;
                        font-weight: 500;
                    }

                    .store-container {
                        width: 100%;

                        .store-label {
                            //font-size: 1rem;
                            font-size: 4vw;
                            color: $blackRussian;
                            font-weight: 500;
                        }

                        .store-content {
                            border: 1px solid #e0e0e0;
                            border-radius: 8px;
                            padding: 10px;
                            width: 90%;
                            margin-top: 15px;

                            .search-result {
                                max-height: 250px;
                                min-height: 250px;
                                overflow-x: hidden;
                                overflow-y: auto;
                                padding-top: 10px;

                                .custom-radio {
                                    padding: 6px;
                                }
                            }

                            .search-box {
                                bricks-text-field {
                                    --bricks-text-field-width: 100%;
                                }
                            }
                        }
                    }
                }

                .attendance-event-section {
                    padding-bottom: 20px;
                }

                .filterbody {
                    display: flex;
                    padding: 20px;
                    overflow-y: auto;
                    margin-bottom: 40px;

                    div {
                        width: 50%;
                    }

                    .category-checkbox {
                        .category-label {
                            font-size: 1vw;
                            font-weight: 400;
                            padding-left: 10px;
                        }
                    }

                    .section {
                        background-color: $white;
                        // padding: 15px;
                        --bricks-radio-button-size: 10px;

                        @media screen and (max-width: 600px) {
                            --bricks-radio-button-size: 10px;
                        }

                        bricks-radio-button-group::part(radio-group-label) {
                            font-size: 18px;
                            color: $blackRussian;
                            font-weight: 600;
                        }

                        bricks-radio-button::part(label) {
                            color: $blackRussian;
                            font-weight: 500;
                        }

                        .event-header-label {
                            font-size: 10px;
                            color: $blackRussian;
                            font-weight: 600;
                            padding-bottom: 15px;
                        }

                        .date-picker {
                            padding-top: 10px;

                            bricks-date-picker::part(date-picker-label) {
                                font-size: 12px;
                            }
                        }
                    }

                    .section:not(:last-child) {
                        border-right: 1px solid $gainsboro;
                        /* Creates a vertical line between sections */
                    }

                    .Absence {
                        padding-left: 20px;
                    }
                }

                .attendance-event-header-label {
                    font-size: 18px;
                    color: #1D1D1F;
                    font-weight: 600;
                    text-align: center;
                    padding-bottom: 17px;
                    margin-left: -25px;
                }

                .attendance-div {
                    display: flex;
                    justify-content: center;
                    padding-bottom: 20px;
                }

                .filter-botton-container {
                    display: flex;
                    justify-content: center;

                    .cancel {
                        margin: 10px 5px;
                    }

                    .submit {
                        margin: 10px 5px;
                    }
                }
            }

            .storeList {
                width: 96%;
                display: flex;
                justify-content: center;
                /* align-items: center; */
                padding: 5px;
                border: 1px solid #000;
                border-radius: 12px;
                min-height: 250px;
            }

            .filter-botton-container {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 60px;
                background-color: #f1f1f1;
                border-top: 1px solid $gainsboro;
                display: flex;
                justify-content: space-between;
                padding: 10px;
                z-index: 1;
                border-radius: 12px;
            }

            .attendance-event {
                display: flex;
                justify-content: center;
            }
        }
    }

    .filter-corporate-popup {
        position: fixed;
        z-index: 9999;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: none;
        background-color: rgba(0, 0, 0, 0.4);
        top: 209px;
        margin-top: 1px;
        opacity: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        #FilterContainer {
            font-size: 14px;
            position: fixed;
            width: 100%;
            top: 209px;
            background: $white;
            border: 1px solid #f5f5f7;
            display: flex;
            flex-direction: column;
            height: 70%;

            #inner-FilterContainer {
                padding-bottom: 60px;
                overflow-y: auto;

                .section {
                    background-color: $white;
                    padding: 15px;
                    --bricks-radio-button-size: 10px;

                    bricks-radio-button-group::part(radio-group-label) {
                        font-size: 4vw;
                        color: $blackRussian;
                        font-weight: 500;
                    }

                    bricks-radio-button::part(label) {
                        color: $blackRussian;
                        font-weight: 400;
                    }

                    .store-container {
                        width: 100%;

                        .store-label {
                            font-size: 18px;
                            color: $blackRussian;
                            font-weight: 500;
                        }

                        .store-content {
                            border: 1px solid #e0e0e0;
                            border-radius: 8px;
                            padding: 10px;
                            width: 90%;
                            margin-top: 15px;

                            .search-result {
                                max-height: 250px;
                                min-height: 250px;
                                overflow-x: hidden;
                                overflow-y: auto;
                                padding-top: 10px;

                                .custom-radio {
                                    padding: 6px;
                                }
                            }

                            .search-box {
                                bricks-text-field {
                                    --bricks-text-field-width: 100%;
                                }
                            }
                        }
                    }
                    .category-label{
                        font-size: 16px;
                    }
                }

                .filterbody {
                    display: flex;
                    padding: 20px;
                    overflow-y: auto;
                    margin-bottom: 40px;

                    div {
                        width: 50%;
                        --bricks-radio-button-size: 10px;
                    }

                    .Absence {
                        padding-left: 40px;
                        border-left: 1px solid #ccc;
                    }

                    .section {
                        background-color: $white;
                        padding-left: 15px;
                        --bricks-radio-button-size: 10px;

                        bricks-radio-button-group::part(radio-group-label) {
                            font-size: 4vw;
                            color: $blackRussian;
                            font-weight: 500;
                        }

                        bricks-radio-button::part(label) {
                            font-size: 3.5vw;
                            color: $blackRussian;
                            font-weight: 500;
                        }

                        .event-header-label {
                            font-size: 10px;
                            color: $blackRussian;
                            font-weight: 500;
                            padding-bottom: 15px;
                        }

                        .date-picker-corporate {
                            padding-top: 15px;

                            bricks-date-picker::part(date-picker-label) {
                                font-size: 12px;
                            }
                        }
                    }

                    .section1 {


                        .date-picker-corporate {
                            padding-top: 15px;

                            bricks-date-picker::part(date-picker-label) {
                                font-size: 12px;
                            }
                        }

                        bricks-radio-button-group::part(radio-group-label) {
                            font-size: 18px;
                            color: #1D1D1F;
                            font-weight: 500;
                        }
                    }

                    .section:not(:last-child) {
                        border-right: 1px solid $gainsboro;
                        /* Creates a vertical line between sections */
                    }
                }

                .attendance-event-header-label {
                    font-size: 18px;
                    color: $blackRussian;
                    font-weight: 500;
                    text-align: center;
                    margin-left: -5vw;
                }

                .attendance-div {
                    padding-top: 15px;
                    padding-bottom:15px;
                    display: flex;
                    justify-content: center;
                }

                .filter-botton-container {
                    display: flex;
                    justify-content: center;

                    .cancel {
                        margin: 10px 5px;
                    }

                    .submit {
                        margin: 10px 5px;
                    }
                }
            }

            .storeList {
                width: 96%;
                display: flex;
                justify-content: center;
                /* align-items: center; */
                padding: 5px;
                border: 1px solid #000;
                border-radius: 12px;
                min-height: 250px;
            }

            .filter-botton-container {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 60px;
                background-color: #f1f1f1;
                border-top: 1px solid $gainsboro;
                display: flex;
                justify-content: space-between;
                padding: 10px;
                z-index: 1;
                border-radius: 12px;
            }

            .attendance-event {
                display: flex;
                justify-content: center;
            }
        }
    }
}