$smokeWhite: #f3f3f3;
$white: #ffffff;
$greyBorder: #dfdfdf;
$black: rgb(5, 0, 0);
$blackRussian: #1D1D1F;
$solitude: #F5F5F7;
$topaz: #86868B;
$nobel: #B1B1B5;
$amethystSmoke: #B08BBE;
$lightCoral: #EE7971;
$paleTeal: #7CCCAF;
$smokeGrey: #6E6E73;
$monsoon: #86868B;
$carrotOrange: #F28E2A;
$aquaHaze: #f3f3f3;
$gainsboro: #ddd;
